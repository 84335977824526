<template>
	<div
		:class="customClass"
		:style="inlineStyle"
		class="tab-card px-3 pt-2 pb-0 tab-custom-data elevation-3 rounded-lg"
	>
		<div class="d-flex mb-2 fw-500">
			<div class="">
				<span class="fw-600">{{ title }}</span>
				Activity
			</div>

			<v-spacer></v-spacer>
			<div class="date d-flex align-items-center">
				<v-icon class="mdi-16px mr-1 primary--text">mdi-calendar-clock-outline</v-icon>
				<span v-if="log && log.added_at">Date : {{ formatDateTime(log.added_at) }}</span>
			</div>
		</div>
		<div class="d-flex mb-2 fw-500">
			<!-- <span class="fw-600 primary--text" v-if="log && log.display_name">{{ log.display_name }} </span> &nbsp; -->
			<span v-if="log && log.description" class="mr-1">{{ log.description }}</span>

			<!-- <span class="fw-600 ml-1" v-if="log && log.module">
				{{ log.module }} <v-icon class="mdi-16px">mdi-open-in-new</v-icon></span
			>
			to New -->
			by
			<span class="ml-1 text-capitalize">
				<v-chip small color="blue darken-2" dark :class="{ 'pl-2': log.user_profile }" class="px-1">
					<v-avatar left v-if="log.user_profile">
						<v-img :src="userImg"></v-img>
					</v-avatar>
					<span class="fw-500">{{ log.user_name }}</span></v-chip
				>
			</span>
			<v-spacer></v-spacer>
			<div class="date d-flex align-items-center" v-if="log && log.ip">
				<span v-if="log && log.ip">Ip Address: {{ log.ip }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Activity",
	props: {
		open_details: {
			type: Boolean,
			default: false,
		},
		log: {
			type: Object,
		},
		title: {
			type: String,
		},
		customClass: {
			type: [String, Array, Object],
			default: () => [],
		},
		inlineStyle: {
			type: [Array, Object],
			default: () => [],
		},
	},
	data() {
		return {
			assign_task: "Test Task",
			isEditing: false,
		};
	},
	computed: {
		userImg: {
			get() {
				let img;
				if (this.log.user_profile) {
					img = this.log.user_profile;
				} else {
					img = process.env.VUE_APP_BASE_URL + "/media/misc/no_photo_found.png";
				}
				return img;
			},
		},
	},
};
</script>
